<script>
    import {
        Accordion,
        AccordionItem,
        Checkbox,
    } from "carbon-components-svelte";
    import { blur } from "svelte/transition";
    import { createEventDispatcher, onMount } from "svelte";
    import "./Facets.css";
    import Filter from "carbon-icons-svelte/lib/Filter.svelte";

    export let selectedSubjects = [];

    let facets = {};
    let selectedSubject = null;
    let isLoading = true;
    const dispatch = createEventDispatcher();

    $: {
        // This reactive statement updates the selectedSubject when the prop changes
        selectedSubject =
            selectedSubjects.length > 0 ? selectedSubjects[0] : null;
    }

    async function fetchFacets() {
        const response = await fetch(
            "https://apiv2.airgapflux.in/search.php?perPage=1",
        );
        const data = await response.json();
        facets = data.facets;
        isLoading = false;
    }

    function handleCheckboxChange(subject) {
        if (selectedSubject === subject) {
            // If the same subject is clicked again, deselect it
            selectedSubject = null;
        } else {
            // Select the new subject
            selectedSubject = subject;
        }

        dispatch("change", selectedSubject ? [selectedSubject] : []);
    }

    onMount(() => {
        fetchFacets();
    });
</script>

<hr />
<h5 style="display: flex; align-items: center;">
    <Filter size="20" style="margin-right: 8px;" /> Filter by Subject
</h5>

{#if isLoading}
    <Accordion skeleton open={false} count={4} />
{:else}
    <Accordion>
        {#each Object.entries(facets) as [category, subjects]}
            <AccordionItem title={category}>
                {#each subjects as subject}
                    <div transition:blur>
                        <Checkbox
                            labelText={subject}
                            checked={selectedSubject === subject}
                            on:change={() => handleCheckboxChange(subject)}
                        />
                    </div>
                {/each}
            </AccordionItem>
        {/each}
    </Accordion>
{/if}

<hr />
