<script>
    import { Tag } from "carbon-components-svelte";
    import tagMapping from "./TagMapping.js";

    export let tags = [];
</script>

{#each tags as tag}
    <Tag type={tagMapping[tag] || "default"} style="margin-top: 0.5rem;"
        >{tag}</Tag
    >
{/each}
