<script>
    import {
        Header,
        HeaderNav,
        HeaderNavItem,
        SideNav,
        SideNavItems,
        SideNavLink,
        SkipToContent,
        Content,
        SideNavDivider,
    } from "carbon-components-svelte";

    import Microscope from "carbon-icons-svelte/lib/Microscope.svelte";
    import Information from "carbon-icons-svelte/lib/Information.svelte";
    import AddAlt from "carbon-icons-svelte/lib/AddAlt.svelte";
    import ExpandCategories from "carbon-icons-svelte/lib/ExpandCategories.svelte";

    let isSideNavOpen = false;
</script>

<Header
    persistentHamburgerMenu={true}
    company="Airgapflux"
    platformName="Search"
    bind:isSideNavOpen
>
    <svelte:fragment slot="skip-to-content">
        <SkipToContent />
    </svelte:fragment>
    <HeaderNav>
        <HeaderNavItem href="https://explore.airgapflux.in">
            <div class="nav-item-content">
                <ExpandCategories size="20" /> <span>Explore Topic</span>
            </div>
        </HeaderNavItem>
        <HeaderNavItem href="https://about.airgapflux.in">
            <div class="nav-item-content">
                <Information size="20" /> <span>About</span>
            </div>
        </HeaderNavItem>
        <HeaderNavItem href="https://about.airgapflux.in">
            <div class="nav-item-content">
                <AddAlt size="20" /> <span>Contribute</span>
            </div>
        </HeaderNavItem>
    </HeaderNav>
</Header>

<SideNav bind:isOpen={isSideNavOpen}>
    <SideNavItems>
        <SideNavDivider />
        <SideNavLink href="https://explore.airgapflux.in">
            <div class="nav-item-content">
                <ExpandCategories size="20" /> <span>Explore Topic</span>
            </div>
        </SideNavLink>
        <SideNavDivider />
        <SideNavLink href="https://about.airgapflux.in">
            <div class="nav-item-content">
                <Information size="20" /> <span>About</span>
            </div>
        </SideNavLink>
        <SideNavDivider />
        <SideNavLink href="https://about.airgapflux.in">
            <div class="nav-item-content">
                <AddAlt size="20" /> <span>Contribute</span>
            </div>
        </SideNavLink>
        <SideNavDivider />
    </SideNavItems>
</SideNav>

<style>
    :global(.nav-item-content) {
        display: flex;
        align-items: center;
        gap: 0.7rem;
    }
</style>
