<script>
    import { Toggle } from "carbon-components-svelte";
    import Tile from "./tileRelated/Tile.svelte";
    import {
        ContextMenu,
        ContextMenuDivider,
        ContextMenuOption,
        Tooltip,
    } from "carbon-components-svelte";
    import Edit from "carbon-icons-svelte/lib/Edit.svelte";
    import CopyFile from "carbon-icons-svelte/lib/CopyFile.svelte";
    import Delete from "carbon-icons-svelte/lib/Delete.svelte";
    import Grid from "carbon-icons-svelte/lib/Grid.svelte";

    export let searchResults = [];
    let wantThumbnail = false;
    let target;
    let currentResourceId;

    function toggleThumbnail() {
        wantThumbnail = !wantThumbnail;
        localStorage.setItem("wantThumbnail", wantThumbnail);
    }

    function processTags(tagsString) {
        if (tagsString) {
            return tagsString.split(",").map((tag) => tag.trim());
        } else {
            return [];
        }
    }

    function copyResourceIdToClipboard() {
        if (currentResourceId !== undefined) {
            navigator.clipboard
                .writeText(currentResourceId.toString())
                .then(() => {
                    console.log(`Copied resource id: ${currentResourceId}`);
                });
        } else {
            console.log("Resource id is undefined");
        }
    }

    function editResource() {
        if (currentResourceId !== undefined) {
            const editUrl = `https://edit.airgapflux.in/?id=${currentResourceId}`;
            window.open(editUrl, "_blank");
        } else {
            console.log("Resource id is undefined");
        }
    }

    function deleteResource() {
        if (currentResourceId !== undefined) {
            const editUrl = `https://edit.airgapflux.in/?id=${currentResourceId}&delete=true`;
            window.open(editUrl, "_blank");
        } else {
            console.log("Resource id is undefined");
        }
    }
</script>

<ContextMenu {target} on:open={(e) => console.log("ContextMenu opened")}>
    <ContextMenuOption
        indented
        labelText="Copy Resource id"
        icon={CopyFile}
        on:click={copyResourceIdToClipboard}
    />
    <ContextMenuDivider />
    <ContextMenuOption
        indented
        labelText="Edit Resource"
        icon={Edit}
        on:click={editResource}
    />
    <ContextMenuDivider />
    <ContextMenuOption
        indented
        kind="danger"
        labelText="Delete"
        icon={Delete}
        on:click={deleteResource}
    />
</ContextMenu>

<div class="heading-container">
    <Grid size={20} />
    <h5>Search Results:</h5>
</div>

<div class="container">
    <div class="sticky-toggle">
        <Toggle
            size="md"
            toggled="true"
            on:change={toggleThumbnail}
            bind:checked={wantThumbnail}
            labelA="Thumbnails"
            labelB="Thumbnails"
        />
    </div>
</div>

<div class="tile-container" bind:this={target}>
    {#each searchResults as result}
        {#if result.Resources}
            <Tile
                title={result.TopicName}
                url={result.Resources}
                resourceId={result.id}
                notes={result.Notes}
                subject={result.Subject}
                {wantThumbnail}
                tags={processTags(result.tags)}
                on:contextmenu={(e) => {
                    currentResourceId = e.detail.resourceId;
                    target = e.currentTarget;
                }}
            />
        {/if}
    {/each}
</div>

<style>
    .heading-container {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }

    .heading-container :global(svg) {
        margin-right: 0.5rem;
    }

    .container {
        margin-bottom: 1rem;
    }

    .sticky-toggle {
        display: flex;
        align-items: center;
    }
    .tile-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
    }
</style>
