<script>
    import { ClickableTile } from "carbon-components-svelte";
    import { fade } from "svelte/transition";
    import TileImage from "./TileImage.svelte";
    import TileTags from "./TileTags.svelte";
    import TileContent from "./TileContent.svelte";
    import "./TileStyles.css";

    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher();

    export let title = "";
    export let url = "";
    export let resourceId = "";
    export let notes = "";
    export let subject = "";
    export let wantThumbnail = false;
    export let tags = [];

    function handleContextMenu(event) {
        event.preventDefault();
        dispatch("contextmenu", { resourceId });
    }
</script>

<br />

{#key resourceId}
    <div
        on:contextmenu={handleContextMenu}
        class="tile-wrapper"
        transition:fade={{ duration: 500 }}
    >
        <ClickableTile href={url} target="_blank" class="tile">
            <TileImage {url} {resourceId} {wantThumbnail} />
            <TileTags {tags} />
            <TileContent {title} {url} {notes} {subject} />
        </ClickableTile>
    </div>
{/key}
