<script>
    import { Tag } from "carbon-components-svelte";
    import { createEventDispatcher } from "svelte";

    export let searchQuery = "";
    export let selectedSubjects = [];

    const dispatch = createEventDispatcher();

    function clearSearch() {
        dispatch("clearSearch");
    }

    function clearSubject(subject) {
        dispatch("clearSubject", subject);
    }
</script>

<div class="search-tags">
    {#if searchQuery}
        <Tag type="purple" filter on:close={clearSearch}>
            Search: {searchQuery}
        </Tag>
    {/if}
    {#each selectedSubjects as subject}
        <Tag type="cyan" filter on:close={() => clearSubject(subject)}>
            Subject: {subject}
        </Tag>
    {/each}
</div>

<style>
    .search-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }
</style>
