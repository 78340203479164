<script>
    import Markdown from "svelte-markdown";

    export let title = "";
    export let url = "";
    export let notes = "";
    export let subject = "";
</script>

<div class="tile-content">
    <p class="tile-title">{title}</p>
    <p class="subject-subject">{subject}</p>
    <div class="link-wrapper">
        <Markdown class="link" source={url} />
    </div>
    {#if notes}
        <Markdown class="notes" source={notes} />
    {/if}
</div>

<style>
    .tile-content {
        padding: 0.5rem;
        width: 100%; /* Ensure the tile takes full width of its container */
    }

    .tile-title {
        font-size: 1.5rem;
        font-weight: bolder;
        margin: 0;
    }

    .subject-subject {
        font-size: 1.25rem;
        font-weight: light;
        opacity: 0.75;
        margin: 0;
    }

    .link-wrapper {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .link {
        font-size: 1rem;
        margin: 0;
        opacity: 0.8;
    }

    .notes {
        font-size: 1rem;
        margin: 0;
    }
</style>
