<script>
    import { createEventDispatcher } from "svelte";
    import { Search, Button } from "carbon-components-svelte";
    import { onMount } from "svelte";
    import SearchIcon from "carbon-icons-svelte/lib/Search.svelte";

    let searchQuery = "";
    const dispatch = createEventDispatcher();

    // Array of placeholder texts
    const placeholders = [
        "Search: DC motor",
        "Search: PID controller",
        "Search: Laplace transform",
        "Search: Fourier transform",
        "Search: Bode plot",
        "Search: Inductor",
        "Search: Rotating Magnetic Field",
    ];

    let currentPlaceholder = "";

    function shufflePlaceholder() {
        const index = Math.floor(Math.random() * placeholders.length);
        currentPlaceholder = placeholders[index] + " (Press Enter)";
    }

    function handleSearch() {
        dispatch("search", searchQuery);
        history.pushState({}, "", `?s=${searchQuery}`);
    }

    function getSearchQueryFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("s") || "";
    }

    onMount(() => {
        searchQuery = getSearchQueryFromUrl();
        shufflePlaceholder();

        const interval = setInterval(shufflePlaceholder, 1300);

        // Clean up the interval when the component is destroyed
        return () => clearInterval(interval);
    });
</script>

<div class="search-container">
    <Search
        icon={SearchIcon}
        size="lg"
        bind:value={searchQuery}
        placeholder={currentPlaceholder}
        on:keydown={(e) => e.key === "Enter" && handleSearch()}
    />
</div>

<style>
    .search-container {
        display: flex;
        align-items: stretch;
    }

    .search-container :global(.bx--search) {
        width: 100%;
    }

    .search-container :global(.bx--btn) {
        flex-shrink: 0;

        /* Reset potentially conflicting styles */
        height: auto;
        min-height: 0;
        line-height: inherit;
    }

    /* Forcefully set the button's height to match the search bar */
    .search-container :global(.bx--btn .bx--btn--primary) {
        height: 100% !important; /* Use !important to override default styles */
    }
</style>
