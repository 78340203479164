const tagMapping = {
    "Practical Application": "green",
    "Quick Overview": "cyan",
    "Deep Dive": "blue",
    "Must Watch": "red",
    "Top Rated": "high-contrast",
    "Editor's Choice": "magenta",
    "Intermediate": "teal",
    "Advanced": "teal",
    "Article": "gray"
  };
  
  export default tagMapping;