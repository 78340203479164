<script>
    import { Content, PaginationNav } from "carbon-components-svelte";
    import SearchBar from "./components/SearchBar.svelte";
    import Facets from "./components/Facets.svelte";
    import SearchResults from "./components/SearchResults.svelte";
    import Header from "./components/Header.svelte";
    import SearchTags from "./components/SearchTags.svelte";
    import { onMount } from "svelte";
    let selectedSubjects = [];
    let searchResults = [];
    let currentPage = 1;
    let totalPages = 0;
    let searchQuery = "";

    function handleSearch(event) {
        searchQuery = event.detail;
        updateUrlAndFetch();
    }

    function handleSubjectChange(event) {
        selectedSubjects = event.detail;
        updateUrlAndFetch();
    }

    function handleClearSearch() {
        searchQuery = "";
        updateUrlAndFetch();
    }

    function handleClearSubject(event) {
        const subjectToRemove = event.detail;
        selectedSubjects = selectedSubjects.filter(
            (subject) => subject !== subjectToRemove,
        );
        updateUrlAndFetch();
    }

    function updateUrlAndFetch() {
        const url = new URL(window.location);
        url.searchParams.set("s", searchQuery);
        url.searchParams.set("subjects", selectedSubjects.join(","));
        history.pushState({}, "", url);
        fetchSearchResults();
    }

    async function fetchSearchResults(page = 1) {
        const subjects = selectedSubjects.join(",");
        const response = await fetch(
            `https://apiv2.airgapflux.in/search.php?Subject=${subjects}&perPage=20&page=${page}&s=${searchQuery}`,
        );
        const data = await response.json();
        searchResults = data.data;
        currentPage = page;
        totalPages = data.totalPages;
    }

    function handlePageChange(event) {
        fetchSearchResults(event.detail.page);
    }

    function getParamsFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        searchQuery = urlParams.get("s") || "";
        selectedSubjects = urlParams.get("subjects")
            ? urlParams.get("subjects").split(",")
            : [];
    }

    onMount(() => {
        getParamsFromUrl();
        fetchSearchResults();
    });
</script>

<Header />
<Content>
    <SearchBar on:search={handleSearch} {searchQuery} />

    <br />
    <SearchTags
        {searchQuery}
        {selectedSubjects}
        on:clearSearch={handleClearSearch}
        on:clearSubject={handleClearSubject}
    />
    <Facets on:change={handleSubjectChange} {selectedSubjects} />
    <br />
    <SearchResults {searchResults} />
    <div class="pagination-container">
        <PaginationNav
            page={currentPage}
            total={totalPages}
            shown={6}
            itemsShown={10}
            on:change={handlePageChange}
            loop={true}
        />
    </div>
</Content>

<style>
    .pagination-container {
        display: flex;
        justify-content: center;
        padding: 1rem 0;
        width: 100%;
        overflow-x: auto;
    }
    /* Add responsiveness for smaller screens */
    @media (max-width: 600px) {
        .pagination-container :global(.bx--pagination-nav) {
            max-width: 100%;
            overflow-x: auto;
        }
        .pagination-container :global(.bx--pagination-nav__list) {
            flex-wrap: nowrap;
        }
        .pagination-container :global(.bx--pagination-nav__page) {
            min-width: 2rem;
        }
    }
</style>
